#actions-wrapper {
  min-height: 150px;
}

.typeahead-selector > li {
	list-style-type: none;
}

.th-wrapper-outer {
  float: right;
	padding: 0px 10px 10px 10px;
}

.th-wrapper-inner {
  position: relative;
}

.typeahead-selector {
  padding: 5px;
  z-index: 999;
  width: 100%;
  background-color: white;
  position: absolute;
  top: 30px;
  border: 1px solid rgb(231, 234, 236);
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.5);
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.5);
}

.right-float{
float: right;
    margin-bottom: 20px;
}

#footer-container-inner {  
    font-size: 21px;
        font-weight: 600;
    font-size: 15px;
    color: #15577e;
  }


  .btn-link {
        font-size: 24px;
    font-weight: 400;
    color: #15577e;
    background-color: transparent;
}

#footer-container {
  width:100%;
  display: flex;
  justify-content: center;
}
#message-modal{
      padding: 6%;
}

.def-item-containes{
  margin: 20px;
}

.close-glossary{
  float: right;
}

.pdf-toolbar{
  float: right;
    margin-top: -129px;
}
